import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { format } from 'date-fns'
import * as queryString from 'query-string'
import { useTranslation, Trans } from 'react-i18next'
import { ScrollAndShow } from '../../components/scroll-and-show'
import { Layout } from '../../components/layout'
import { useNews } from '../../hooks/useNews'
import { SEO } from '../../components/seo'

const NewsPage = ({ location }) => {
	const [t, i18n] = useTranslation()

	const { news, fetchNews, totalPage } = useNews()

	const { page } = queryString.parse(location.search);

	useEffect(() => {
		fetchNews(Number(page))
	}, [page])

	return (
		<Layout>
			<SEO
				title={`NEWS | RUBIA ルビア 渋谷メキシカン【朝食・ランチ・バー】`}
				description=""
				keywords={['メキシコ料理', '渋谷', 'ファインメキシカン', 'ルビア', 'sarasa']}
			/>
			<h1 className="h1">NEWS | RUBIA ルビア / DJ sarasa</h1>
			<div className="news-page">
				<ScrollAndShow>
					<div className="news-text font-lato">NEWS ARCHIVE</div>
				</ScrollAndShow>
				<ScrollAndShow>
					<div className="inner">
						{news.map((newsItem, index) => (
							<Link to={`/news/${newsItem.id}`}>
								<div className="news-row" key={index}>
									<div className="font-lato inner-date">{format(new Date(newsItem.date), 'yyyy.MM.dd')}</div>
									<div className={`${i18n.language === 'en' && newsItem.titleEn ? 'font-lato' : 'font-yu'} inner-title`}>
										{i18n.language === 'en' && newsItem.titleEn || newsItem.title}
									</div>
								</div>
							</Link>
						))}
					</div>
				</ScrollAndShow>
				<ScrollAndShow>
					<div className="pagination-buttons font-lato">
						{totalPage > 0 && (Number(page) && Number(page) !== 1) ? (
							<Link to={`/news?page=${Number(page) - 1}`}>← PREV</Link>
						) : (
							<a className="disabled">← PREV</a>
						)}
						{totalPage > 0 && Number(page) !== totalPage ? (
							<Link to={`/news?page=${Number(page) ? Number(page) + 1 : 2}`}>NEXT →</Link>
						) : (
							<a className="disabled">NEXT →</a>
						)}
					</div>
				</ScrollAndShow>
			</div>
		</Layout>
	)
}

export default NewsPage

