import { useState, useEffect } from 'react'
import { request } from '../lib/axisClient'
import { News } from '../types/news'

export const useNews = () => {
	const [latestNews, setLatestNews] = useState<News[]>([])
	const [news, setNews] = useState<News[]>([])
	const [presses, setPresses] = useState<News[]>([])
	const [totalPage, setTotalPage] = useState(0)
	const [totalPressPage, setTotalPressPage] = useState(0)
	
	const limit = 10;

	const fetchNews = async (page = 1) => {
		const offset = page === 1 ? 0 : (page - 1) * limit
		try {
			const res = await request({
				url: `/news?limit=${limit}&offset=${offset}`,
				method: 'GET'
			})
			setLatestNews([res.contents[0], res.contents[1], res.contents[2]])
			setNews(res.contents)
			setTotalPage(Math.ceil(res.totalCount / limit))
		} catch (e) {
			console.log(e)
		}
	}

	const fetchPresses = async (page = 1) => {
		const offset = page === 1 ? 0 : (page - 1) * limit
		try {
			const res = await request({
				url: `/news?filters=isPress[equals]true&limit=${limit}&offset=${offset}`,
				method: 'GET'
			})
			setPresses(res.contents)
			setTotalPressPage(Math.ceil(res.totalCount / limit))
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		fetchNews()
		fetchPresses()
	}, [])

	return { news, presses, latestNews, fetchNews, fetchPresses, totalPage, totalPressPage }
}
